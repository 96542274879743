.header {
  border-bottom: 1px solid #1f1f1f;
}

.modalOverlay {
  background: var(--overlay-bg, rgba(255, 255, 255, 0.6)) !important;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.levelsGroup {
  color: black !important;
}

.pageContainer {
  @media (max-width: 64em) {
    flex-direction: column;
  }
}

.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.emptyList {
  display: flex;
  justify-content: center;
  align-items: center;
}
.leader {
  background: #141414;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
}

.leader.active {
  border: 1px solid var(--Primary-1, #ea3359);
}

.event {
  background: #141414;
  border-radius: 8px;
  padding: 12px 20px 12px 12px;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
}

.event:hover {
  opacity: 0.8;
}
.icon {
  height: 48px;
  width: 48px;
  flex: 0 0 48px;
  border-radius: 4px;
}
.topic {
  font-size: 10px !important;
  color: rgba(255, 255, 255, 0.6);
}
.date {
  font-size: 12px !important;
  font-weight: 700 !important;
  text-align: center;
}

.rightBlock {
  min-width: 300px;
}

.lessonBlock {
  width: 249px;
  padding: 12px;
  flex-direction: column;
  background: #000;
  border: 1px solid #151515;
  border-radius: 12px;
}
.lessonBlock:hover {
  opacity: 0.8;
}
.lessonName {
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-end;
}

.leadersLink {
  font-size: 16px;
  font-weight: 600;
  color: var(--electric-lime-color);
}

.leadersLink:hover {
  opacity: 0.8;
}

.firstImage {
  width: 69px !important;
  height: 69px;
  flex-shrink: 0;
  border-radius: 50% !important;
  margin-bottom: 7px;
}

.userImage {
  width: 26px !important;
  height: 26px;
  flex-shrink: 0;
  border-radius: 50% !important;
  margin-right: 7px;
}
.firstLeader {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: #ea3359;
  border-radius: 50% !important;
}

.list {
  height: 554px;
  @media (max-width: 64em) {
    height: auto;
  }
}

.lessons {
  @media (max-width: 36em) {
    justify-content: center !important;
  }
}
