.navbar {
  min-height: 100%;
  min-width: 247px;
  padding: 16px;
  margin-right: 24px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  background: #0b0b0b;
  transition: 0.3s all;
  @media (max-width: 48em) {
    margin-right: 0;
    padding: 0;
  }
}

.smallNavbar {
  min-width: 90px !important;
}

.header {
  padding-bottom: 45px;
  border-bottom: 1px solid #1f1f1f;
  @media (max-width: 48em) {
    padding-bottom: 25px;
  }
}

.headerMobile {
  display: flex;
  align-items: center;
}

.links {
  flex: 1;
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
}

.linksInner {
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
}

.logo {
  margin: 0 auto;
  height: 94px;
  width: 94px !important;
  transition: 0.3s all;
}

.smallLogo {
  margin-left: -8px;
  width: 70px !important;
  height: 70px !important;
}

.dropdownMobile {
  border-radius: 6px !important;
  background: #0b0b0b !important;
  border: 0 !important;
}

.dropdownMobileValue {
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 500;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s;
  font-size: 14px;
  justify-content: space-between;
  background: var(--electric-lime-color);
  color: var(--mantine-color-black);
  path {
    fill: var(--mantine-color-black);
    transition: all 0.3s;
  }
  svg:first-child {
    margin-right: 20px;
  }
}
