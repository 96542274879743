.header {
  border-bottom: 1px solid #1f1f1f;
  justify-content: flex-end;
}

.modalOverlay {
  background: var(--overlay-bg, rgba(255, 255, 255, 0.6)) !important;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.levelsGroup {
  color: black !important;
}

.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.multiselectOption {
  color: var(--mantine-color-black) !important;
}

.searchInput {
  padding: 4px;
}

.errorField {
  color: var(--mantine-color-error);
}

.lesson {
  width: 100%;
  border-radius: 12px;
  background: #141414;
  padding: 24px 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: var(--mantine-color-white) !important;
  transition: all 0.3s;
}
.lesson.disabled {
  opacity: 0.1;
  pointer-events: none;
}

.lesson:hover {
  opacity: 0.8;
}
.lessons {
  display: flex;
  flex-direction: column;
}
