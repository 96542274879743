.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px 0 24px 24px;
  display: flex;
  flex-grow: 1;
  @media (max-width: 48em) {
    display: flex;
    flex-direction: column;
    padding: 24px;
  }
}

.lessonNav {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  flex: 0 0 220px;
  @media (max-width: 48em) {
    padding-right: 0;
  }
}

.navItem {
  height: 52px;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  background: #141414;
  color: var(--mantine-color-white);
  padding-left: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nextPageButton {
  font-size: 14px !important;
  height: 49px !important;
  text-align: left !important;
}

.nextPageButtonInner {
  justify-content: space-between !important;
}

.navItem.active {
  background: var(--electric-lime-color);
  color: var(--mantine-color-black);
}

.lessonContent {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #141414;
  padding: 32px;
  @media (max-width: 30em) {
    padding: 16px;
  }
}

.homeWorkInputContainer {
  border-radius: 6px;
  border: 1px solid #212121;
  padding: 9px !important;
  @media (max-width: 30em) {
    flex-direction: column;
  }
}

.textarea {
  width: 90%;
  @media (max-width: 30em) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.commentWrap {
  flex-direction: column;
  margin-bottom: 40px !important;
  padding-left: 70px !important;
  position: relative !important;
  @media (max-width: 30em) {
    padding-left: 50px !important;
  }
}
.avatar {
  position: absolute;
  left: 0;
  top: 0;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50px !important;
  @media (max-width: 30em) {
    width: 40px !important;
    height: 40px !important;
  }
}

.textareaInput {
  padding: 7px 4px;
}

.sendFileButton {
  @media (max-width: 30em) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 4px;
  }
}

.comment {
  font-weight: 500;
}
.commentAuthor {
  color: var(--electric-lime-color);
  font-weight: 500;
}
.commentDate {
  color: var(--shark-body-text-color);
  font-weight: 500;
}

.file {
  cursor: pointer;
  transition: ease 300ms;
}
.file:hover {
  opacity: 0.8;
}

.videoIframe {
  width: 100%;
  height: 485px;
  @media (max-width: 48em) {
    height: 285px;
  }
}
