.page {
  min-height: 100vh;
  width: 100vw;
  background-color: var(--mantine-color-black);
  display: flex;
  @media (max-width: 48em) {
    flex-direction: column-reverse;
  }
}

.page.white {
  background-color: var(--mantine-color-white);
}

.formContainer {
  width: 50%;
  padding: 77px 10%;
  margin: auto;
  @media (max-width: 48em) {
    margin-top: -20px;
    width: 100%;
    border-radius: 24px 24px 0px 0px;
    background-color: #000;
  }
}

.formContainer.fullHeight {
  min-height: calc(100vh - 20px) !important;
  margin: 20px 0 0 0 !important;
}

.backgroundImage {
  width: 50% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end !important;
  padding: 77px 5% !important;
  @media (max-width: 48em) {
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    height: 40vh !important;
  }
}
.backgroundImage.hide {
  display: none !important;
}

.submitButton {
  align-self: flex-end;
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.logo {
  height: 124px;
  width: 124px !important;
}

.text {
  color: #c8c8c8 !important;
}

.form {
  max-width: 436;
  margin: 0 auto;
}

.mobileBlock {
  border-radius: 24px;
  background: #000;
  width: 335px;
  padding: 20px 20px 24px 20px;
}

.buttonsBlock {
  display: flex;

  background-color: var(--shark-primary-color);
  border-radius: 28px;
  padding: 3px;
}

.mobileLink {
  height: 46px;
  width: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  transition: 0.3s ease;
  border-radius: 28px;
}
.mobileLink.active {
  color: #090d20;
  background: white;
}
.desktopImageContent {
  @media (max-width: 48em) {
    display: none;
  }
}

.logoMobile {
  width: 124px;
  height: 124px;
  margin: 0 auto 45px;
}
