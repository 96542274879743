.submitButton {
  align-self: flex-end;
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.codeInput {
  font-size: 18px !important;
  font-weight: 700;
  height: 85px !important;
  width: 94px !important;
  padding: 16px 38px !important;
}

.codeInputWrapper {
  margin-right: 20px !important;
}
.codeInputWrapper:last-child {
  margin-right: 0px !important;
}

.forgotPasswordButton {
  font-weight: 700;
  cursor: pointer !important;
}

.errorField {
  color: var(--mantine-color-error);
}
