.card {
  flex-direction: column;
  justify-content: space-between !important;
  width: 30%;
  border-radius: 12px;
  border: 1px solid #fff;
  background: #000 !important;
  color: var(--mantine-color-white) !important;
  margin: 6px;
}

.button {
  height: 47px !important;
  min-height: 47px !important;
  background: var(--mantine-color-white) !important;
  color: var(--mantine-color-black) !important;
}

.image {
  height: 140px !important;
  flex: 0 0 140px;
}
