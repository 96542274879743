.submitButton {
  align-self: flex-end;
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.errorField {
  color: var(--mantine-color-error);
}

.logo {
  height: 124px;
  width: 124px !important;
}

.successSection {
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  max-width: 745px;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalOverlay {
  background: var(--overlay-bg, rgba(255, 255, 255, 0.6)) !important;
}

.selectDropdown {
  color: black !important;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px;
}

.pageTitleContainer {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #1f1f1f;
}

.courseCard {
  flex-direction: column;
  width: 30%;
  border-radius: 12px;
  border: 1px solid #fff;
  background: #000 !important;
  color: var(--mantine-color-white) !important;
  padding: 20px !important;
  margin: 0 6px;
}

.courseButton {
  background: var(--mantine-color-white) !important;
  color: var(--mantine-color-black) !important;
}

.emptyList {
  height: 100%;
  justify-content: center !important;
  align-items: center !important;
}

.list {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 48em) {
    justify-content: center !important;
  }
}

.courseCard {
  width: 314px !important;
  @media (max-width: 64em) {
    width: 280px !important;
  }
}
