.richTextContent {
  width: 100%;
}

.richTextContent img {
  max-width: 100%;
}
.richTextContent a {
  text-decoration: underline;
}
