.passwordIcon {
  cursor: pointer;
}

.blackInput {
  background-color: #141414 !important;
  color: var(--mantine-color-white) !important;
}
.blackWrapper path {
  fill: var(--mantine-color-white) !important;
}
