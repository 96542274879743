.header {
  border-bottom: 1px solid #1f1f1f;
}

.emptyList {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center !important;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;

  @media (max-width: 30em) {
    justify-content: center !important;
  }
}

.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px;
  flex-grow: 1;
}

.mentorCard {
  width: 249px !important;
  height: 350px;
  width: 25%;
  overflow: hidden;
  border-radius: 18px;
  border: 1px solid #151515;
  background: #0b0b0b;
  margin: 12px 12px 30px 12px;
  flex-direction: column;
  align-items: center;
}

.image {
  height: 201px;
  margin-bottom: 20px;
}

.button {
  background: #5765f2 !important;
  width: 154px !important;
  height: 44px !important;
  border-radius: 6px;
}

.button:hover {
  background: #5765f2;
  opacity: 0.9;
}
