.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px;
  flex-grow: 1;
}

.logo {
  width: 100%;
  height: 201px;
  margin-bottom: 29px;
  margin-top: 29px;
  border-radius: 18px !important;
}
.content h4 {
  font-size: 20px;
}
