.header {
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 64em) {
    flex-direction: column;
  }
}

.emptyList {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center !important;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
}

.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px;
  flex-grow: 1;
}
.image {
  height: 32px !important;
  width: 32px !important;
  border-radius: 50% !important;
  margin: 0 20px;
}

.button {
  background: #5765f2;
  width: 154px;
  height: 44px;
  border-radius: 6px;
}

.button:hover {
  background: #5765f2;
  opacity: 0.9;
}

.firstLeader {
  width: 30px;
  height: 30px;
  background: #ea3359;
  border-radius: 50% !important;
}

.backButton {
  height: auto !important;
  padding: 12px 22px !important;
  border-radius: 12px !important;
  background: #0b0b0b !important;
  color: var(--shark-body-text-color) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  @media (max-width: 64em) {
    align-self: flex-start !important;
    margin-bottom: 20px;
  }
}

.searchInput {
  height: 60px !important;
  width: 273px !important;
  font-size: 16px !important;
  padding: 0px 60px 0 24px !important;
  border-radius: 12px !important;
  border: 1px solid #101010 !important;
  background: transparent !important;
  color: var(--shark-body-text-color) !important;
  @media (max-width: 30em) {
    width: 100% !important;
  }
}

.sectionWrapper {
  @media (max-width: 64em) {
    align-self: flex-end !important;
  }
}

.sectionWrapper {
  @media (max-width: 30em) {
    width: 100% !important;
  }
}

.sectionInput {
  margin-right: 16px;
}

.pagination {
  display: flex;
  justify-content: flex-end !important;
}
.paginationControl {
  height: 49px !important;
  width: 49px !important;
  border-radius: 4px !important;
  border: 1px solid #141414 !important;
  color: #939393 !important;
  font-size: 18px !important;
  font-weight: 400;
  background: transparent !important;
}

.paginationControl[data-active='true'] {
  background: var(--electric-lime-color) !important;
  color: #000 !important;
  font-weight: 600;
}

.paginationControl:first-child,
.paginationControl:last-child {
  border: 1px solid #6e6e6e !important;
}

.paginationControl:first-child path,
.paginationControl:last-child path {
  fill: #6e6e6e !important;
}
.paginationControl:first-child svg,
.paginationControl:last-child svg {
  width: 25px !important;
  height: 25px !important;
}

.activeItem {
  border: 1px solid var(--electric-lime-color);
}
