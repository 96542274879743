.control {
  font-weight: 500;
  display: block;
  width: 100%;
  color: var(--mantine-color-text);
  font-size: var(--mantine-font-size-sm);

  @mixin hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-7)
    );
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }
}

.link {
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding: 10px 20px !important;
  font-size: 14px;
  margin-bottom: 10px !important;

  @mixin hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-7)
    );
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }
}
.smallLink {
  font-size: 10px;
  padding: 5px !important;
}

.chevron {
  transition: transform 200ms ease;
}

.navLink {
  display: flex;
  align-items: center;
  width: 100%;
  color: #9c9ca4;
  font-weight: 500;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s;
  font-size: 14px;
  justify-content: space-between;
  svg:first-child {
    margin-right: 20px;
  }
}
.hideMargin {
  svg {
    margin-right: 0px !important;
  }
}

.navLinkActive {
  background: var(--electric-lime-color);
  color: var(--mantine-color-black);
  path {
    fill: var(--mantine-color-black);
    transition: all 0.3s;
  }
}

.linkContainer {
  display: flex;
  align-items: center;
  width: 100%;
}
