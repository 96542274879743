.submitButton {
  align-self: flex-end;
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.errorField {
  color: var(--mantine-color-error);
}

.logo {
  height: 124px;
  width: 124px !important;
}

.successSection {
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  max-width: 745px;
  padding: 0 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
