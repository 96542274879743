.page {
  padding: 24px;
  width: 100%;
}
.header {
  border-bottom: 1px solid #1f1f1f;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.emptyList {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center !important;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px;
  flex-grow: 1;
}
