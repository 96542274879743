.page {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
}

.header {
  padding-bottom: 40px;
  margin-bottom: 24px;
  @media (max-width: 48em) {
    padding-bottom: 24px;
  }
  border-bottom: 1px solid #1f1f1f;
}

.select {
  background: #000000 !important;
  color: #9c9ca4 !important;
  border-radius: 6px;
  border: 1px solid #222 !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  height: 50px !important;
  @media (max-width: 30em) {
    font-size: 16px !important;
  }
}

.select:focus {
  border: 1px solid #222 !important;
}

.section {
  width: 60px;
}

.title {
  @media (max-width: 48em) {
    font-size: 18px !important;
  }
}
.dropdown {
  border-radius: 6px;
  border: 0.5px solid rgba(255, 255, 255, 0.3) !important;

  background: #000 !important;
  color: #ffffff !important;
}

.option {
  margin: 4px 0px;
  font-size: 14px !important;
  @media (max-width: 30em) {
    font-size: 16px !important;
  }
}

.option:hover {
  background: #0b0b0b !important;
}
.option[data-combobox-active='true'] {
  background: #0b0b0b !important;
}
.backToCourseButton {
  font-size: 14px;
  font-weight: normal;
  color: #9c9ca4 !important;
  height: 64px !important;
  border-radius: 12px;
  background: #0b0b0b !important;
}
