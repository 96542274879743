.page {
  padding: 24px;
  width: 100%;
}
.header {
  border-bottom: 1px solid #1f1f1f;
}

.listItem {
  display: flex;
  justify-content: space-between;
}

.emptyList {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center !important;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.pageContent {
  background: #0b0b0b;
  border-radius: 18px;
  margin-top: 30px;
  padding: 24px;
}

.avatar {
  height: 150px !important;
  width: 150px !important;
  border-radius: 50% !important;
  cursor: pointer;
}

.uploadAvatarIcon {
  position: absolute;
  top: 110px;
  left: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  background: var(--mantine-color-white);
}

.halfWidthSetting {
  width: 46%;
  margin: 0 0 30px 0;
  @media (max-width: 30em) {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
