body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white !important;
}

#root {
  min-height: 100vh;
}

code {
  font-family: 'Courier New', monospace;
}

a {
  color: inherit;
}
